import { Show, type Component } from "solid-js";
import { LineImage, LineAttributes } from "./";
import { type FormattedCart } from "~/routes/(layout)/checkout/[cart_id]";
import { numberWithCommas } from "~/utils/helpers";

export const ProductSummary: Component<{
  line: FormattedCart['Lines'][number];
  thumbnail?: string;
  sku?: number | string;
  details?: string;
  qty?: number;
  listPrice?: number;
  partnerPrice?: number;
}> = (props) => {
  return (
    <div
      class="flex  flex-row justify-between items-start py-4 border-b"
      classList={{
        "bg-red-50 border border-red-100 -mx-2 px-2":
          !!props.line?.RequiresBackorderSelection,
      }}
    >
      <div class="flex">
        <LineImage line={props.line} size="sm" />
        <LineAttributes line={props.line} />
      </div>
      <div class="flex flex-col items-end md:flex-row md:items-center md:gap-2">
        <Show when={Math.abs(props.line.Discount) > 0}>
          <p class="text-sm text-roma-dark-grey line-through font-light">
            ${(Math.abs(props.line.Discount) + props.line.Amount).toFixed(2)}
          </p>
        </Show>

        <Show when={props.line.Amount !== 0}>
          <p class="font-bold">
            ${numberWithCommas(props.line.Amount.toFixed(2))}
          </p>
        </Show>
      </div>
    </div>
  );
};